<template>
  <v-content
    :style="this.$store.state.darkTheme ? styleDark : styleLight "
  >
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
  </v-content>
</template>

<script>
  import config from '@/config'

  export default {
    name: 'CoreView',

    data: () => ({
      bg_dark: config.colors.gricad_darkblue_dark2,
      bg_light: '#faffff', // white
    }),

    metaInfo () {
      return {
        title: 'PERSEUS NG',
      }
    },

    computed: {
      styleDark () {
        return {
          'background-color': this.bg_dark, // colors.gricad_darkblue_dark2
          color: 'white',
        }
      },
      styleLight () {
        return {
          'background-color': this.bg_light,
          color: 'black',
        }
      },
      style2 () {
        return {
          'background-image': `url(${this.$store.state.IMG_NEBULEUSE})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }
      },
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },

  }
</script>
